
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Medical Histories</h5>
      <div class="row gy-2 justify-content-between">
        <div class="col-md-5 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm"  align="middle">
              <template v-slot:cell(number)="data">
                <div @click="navigateTo(data.item.id)" style="min-width:4rem;" class="cursor-pointer">
                  <span>{{ data.item.id }} </span>
                </div>
              </template>
              <template v-slot:cell(patient)="data">
                <div style="min-width:12rem;" @click="navigateTo(data.item.id)" v-if="data.item.patient" class="cursor-pointer">
                  <h6>{{ data.item.patient.friendly_id }} </h6>
                  <p class="mb-0"><strong>Name:</strong> {{ data.item.patient.first_name }} {{ data.item.patient.last_name }} </p>
                  <p class="mb-0"><strong>Phone:</strong> {{ data.item.patient.mobile }} </p>
                </div>
              </template>
              <template v-slot:cell(weight)="data">
                <div @click="navigateTo(data.item.id)" style="min-width:4rem" class="cursor-pointer">
                  <span>{{ data.item.weight }} </span>
                </div>
              </template>
              <template v-slot:cell(blood_pressure)="data">
                <div @click="navigateTo(data.item.id)" style="min-width:4rem" class="cursor-pointer">
                  <span>{{ data.item.blood_pressure }} </span>
                </div>
              </template>
              <template v-slot:cell(pulse)="data">
                <div @click="navigateTo(data.item.id)" style="min-width:4rem" class="cursor-pointer">
                  <span>{{ data.item.pulse }} </span>
                </div>
              </template>
              <template v-slot:cell(drugs_count)="data">
                <div @click="navigateTo(data.item.id)" style="min-width:4rem" class="cursor-pointer">
                  <p class="mb-0">Total: <strong>{{ data.item.drugs_count }}</strong> </p>
                  <p class="mb-0">Pending: <strong>{{ data.item.pending_drugs_count }}</strong> </p>
                  <p class="mb-0">Dispensed: <strong>{{ data.item.dispensed_drugs_count }}</strong> </p>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <div @click="navigateTo(data.item.id)" style="min-width:6rem;max-width:6rem;" class="cursor-pointer">
                  {{ $filters.date(data.item.created_at, 1) }}
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="dropdown dropstart no-arrow">
                  <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click.prevent="navigateTo(data.item.id)" class="dropdown-item" type="button">
                        View Record
                      </button>
                    </li>
                    <li>
                      <button @click.prevent="downloadReceipt(data.item.id)" class="dropdown-item" 
                      type="button"> Print Record </button>
                    </li>
                  </ul>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>


<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue'
  
export default {
  name: "histories",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      searchQuery:"",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "number",
          label: "Ref No",
        },
        {
          key: "patient",
          label: "Patient",
        },
        {
          key: "weight",
          label: "Weight",
        },
        {
          key: "blood_pressure",
          label: "Blood Pressure",
        },
        {
          key: "pulse",
          label: "Pulse",
        },
        {
          key: "drugs_count",
          label: "Drugs Count",
        },
        {
          key: "created_at",
          label: "Date",
        },
        "action"
      ],
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    pageData(){
      return this.$store.state.medicalHistory.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0';
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path:`/medical-histories/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("medicalHistory/fetchItems", payload)
      .then(response => this.isLoading = false)
    },
    downloadReceipt(itemId) {
      const payload = {
        url: '/generate-record',
        data: {  model_id: btoa(itemId), receipt: 'e6bb4808_1b46_45bd_8a5f_aadd2066a80e' }
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.serverReceipt(payload)
          .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
              this.alertError('Could not generate receipt');
          }
      })
    }, 
    initializeAll(){
    },
  },
  created() {
    this.fetchItems()
  }
}

</script>
  
  